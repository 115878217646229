import React from 'react';


class Blog extends React.Component{
    render () {
      return (
                <div>
                   <header className="main-header position-absolute w-100">
                    <nav className="navbar navbar-expand-xl navbar-dark sticky-header">
                      <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <a href="/home" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                          <img src="assets/img/PAlogo.svg" alt="logo" style={{width: '220px'}} className="img-fluid logo-white" />
                          <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                        </a>
                        <a className="navbar-toggler position-absolute right-0 border-0 " href="#offcanvasWithBackdrop" role="button">
                          <span className="far fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" />
                        </a>
                        <div className="clearfix" />
                        <div className="collapse navbar-collapse justify-content-center">
                          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li className="nav-item dropdown">
                              <a className="nav-link" href="/home" role="button" aria-expanded="false">
                                Home
                              </a>
                            </li>
                            <li><a href="about" className="nav-link">About</a></li>
                            <li><a href="Invest" className="nav-link">Invest</a></li>
                            <li><a href="blog" className="nav-link active activeguy">Blog</a></li>
                          </ul>
                        </div>
                        <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                          <a href="https://admin.alphawealth.io" className="btn btn-link text-decoration-none me-2">Sign In</a>
                          <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                        </div>
                        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop">
                          <div className="offcanvas-header d-flex align-items-center mt-4">
                            <a href="/home" className="d-flex align-items-center mb-md-0 text-decoration-none">
                              <img src="assets/img/logo-color.png" alt="logo" className="img-fluid ps-2" />
                            </a>
                            <button type="button" className="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close"><i className="far fa-close" /></button>
                          </div>
                          <div className="offcanvas-body">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                              <li className="nav-item dropdown">
                                <a className="nav-link " href="/home" role="button" aria-expanded="false">
                                  Home
                                </a>
                              </li>
                              <li><a href="about" className="nav-link">About</a></li>
                              <li><a href="invest" className="nav-link">Invest</a></li>
                              <li><a href="blog" className="nav-link">Blog</a></li>
                            </ul>
                            <div className="action-btns mt-4 ps-3">
                              <a href="https://admin.alphawealth.io" className="btn btn-outline-primary me-2">Sign In</a>
                              <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </header>
                  <section className="page-header  overflow-hidden ptb-120 bg-dark" style={{background: 'url("assets/img/page-header-bg.svg")no-repeat bottom left'}}>
                    <div className="container">
                      <br/>
                      <br/>
                      <div className="row">
                        <div className="col-lg-8 col-md-12">
                          <h1 className="display-5 fw-bold">Blog</h1>
                        </div>
                      </div>
                      <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5" />
                    </div>
                  </section>
                  <div className="style-guide">
                    <section className="home-blog-section ptb-120 bg-light">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center">
                              <h4 className="h5 text-primary">Blog</h4>
                              <h2>Check our Latest Article</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="blog-grid">
                            <div className="featured-post-wrapper">
                              <div className="blog-item">
                                <div className="blog-content">
                                  <div className="blog-media">
                                    <img src="assets/img/blog/blog-1.jpg" alt="article" className="img-fluid" />
                                  </div>
                                  <div className="blog-text p-4 p-lg-5">
                                    <span className="featured-badge"><i className="fas fa-bookmark text-warning" data-toggle="tooltip" data-placement="top" title data-original-title="Featured" /></span>
                                    <a href="0#" target="_blank">
                                      <h3 className="h5">Successful Launch of PiggyAlpha Mobile App</h3>
                                    </a>
                                    <p>Today being the 1st day in Decmeber, PiggyAlpha has launched their mobile app on the app and play store avaialble for download...</p>
                                    <div className="read-more-link">
                                      <a href="0#" target="_blank" className="mt-2 d-inline-flex align-items-center btn btn-sm btn-pill font-weight-bold"><span>Read More</span>
                                        <i className="far fa-arrow-right ms-2" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="posts-wrapper">
                              <div className="blog-item">
                                <div className="blog-content">
                                  <div className="blog-media">
                                    <img src="assets/img/blog/blog-4.jpg" alt="article" className="img-fluid" />
                                  </div>
                                  <div className="blog-text p-4 p-lg-5">
                                    <a href="0#" target="_blank">
                                      <h3 className="h5">Piggy Alpha records over a thousand Downloads</h3>
                                    </a>
                                    <p>Over 1000 users have succesully downloaded the piggyalpha application on first launch.</p>
                                    <div className="read-more-link">
                                      <a href="0#" target="_blank" className="mt-2 d-inline-flex align-items-center btn btn-sm btn-pill font-weight-bold"><span>Read More</span>
                                        <i className="far fa-arrow-right ms-2" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="blog-item">
                                <div className="blog-content">
                                  <div className="blog-media">
                                    <img src="assets/img/blog/blog-2.jpg" alt="article" className="img-fluid" />
                                  </div>
                                  <div className="blog-text p-4 p-lg-5">
                                    <a href="0#" target="_blank">
                                      <h3 className="h5">Testers Required</h3>
                                    </a>
                                    <p>Lorem ipsibsdc dibulsdbiudb sidb sdsdbui</p>
                                    <div className="read-more-link">
                                      <a href="0#" target="_blank" className="mt-2 d-inline-flex align-items-center btn btn-sm btn-pill font-weight-bold"><span>Read More</span>
                                        <i className="far fa-arrow-right ms-2" /></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <footer className="footer-section">
                    <div className="footer-top footer-light ptb-120">
                      <div className="container">
                        <div className="row justify-content-between">
                          <div className="col-md-8 col-lg-5 mb-md-4 mb-lg-0">
                            <div className="footer-single-col">
                              <div className="footer-single-col mb-4">
                                <img src="assets/img/logo-white.png" alt="logo" className="img-fluid logo-white" />
                                <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                              </div>
                              <p>Our latest news, articles, and resources, we will sent to
                                your inbox weekly.</p>
                              <form className="newsletter-form position-relative d-block d-lg-flex d-md-flex">
                                <input type="text" className="input-newsletter form-control me-2" placeholder="Enter your email" name="email" required autoComplete="off" />
                                <input type="submit" defaultValue="Subscribe" data-wait="Please wait..." className="btn btn-primary mt-3 mt-lg-0 mt-md-0" />
                              </form>
                              <div className="ratting-wrap mt-4">
                                <h6 className="mb-0">10/10 Overall rating</h6>
                                <ul className="list-unstyled rating-list list-inline mb-0">
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                            <div className="row">
                              <div className="col-md-4 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                  <h3>Pages</h3>
                                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                                    <li><a href="/home" className="text-decoration-none">Home</a></li>
                                    <li><a href="about" className="text-decoration-none">About</a></li>
                                    <li><a href="invest" className="text-decoration-none">Invest</a></li>
                                    <li><a href="blog" className="text-decoration-none">Blog</a></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-4 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                  <h3>Legal</h3>
                                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                                    <li><a href="terms" className="text-decoration-none">Terms of Service</a></li>
                                    <li><a href="privacy" className="text-decoration-none">Privacy Policy</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer-bottom footer-light py-4">
                      <div className="container">
                        <div className="row justify-content-between align-items-center">
                          <div className="col-md-7 col-lg-7">
                            <div className="copyright-text">
                              <p className="mb-lg-0 mb-md-0">2021 PiggyAlpha. </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4">
                            <div className="footer-single-col text-start text-lg-end text-md-end">
                              <ul className="list-unstyled list-inline footer-social-list mb-0">
                                <li className="list-inline-item"><a href="#"><i className="fab fa-facebook-f" /></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-instagram" /></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-dribbble" /></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-github" /></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                </div>
            
      );
    }
  };

export default Blog;
