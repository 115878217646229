import React from 'react';
import {Animated} from "react-animated-css";


class Home extends React.Component{
    render () {
      return (
              <div>
               
                  <header className="main-header position-absolute w-100">
                    <nav className="navbar navbar-expand-xl navbar-dark sticky-header">
                      <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <a href="/home" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                          <img src="assets/img/PAlogo.svg" alt="logo" style={{width: '220px'}} className="img-fluid logo-white" />
                          <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                        </a>
                        <a className="navbar-toggler position-absolute right-0 border-0 " href="#offcanvasWithBackdrop" role="button">
                          <span className="far fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" />
                        </a>
                        <div className="clearfix" />
                        <div className="collapse navbar-collapse justify-content-center">
                          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li className="nav-item dropdown">
                              <a className="nav-link active activeguy" href="/home" role="button" aria-expanded="false">
                                Home
                              </a>
                            </li>
                            <li><a href="about" className="nav-link">About</a></li>
                            <li><a href="Invest" className="nav-link">Invest</a></li>
                            <li><a href="blog" className="nav-link">Blog</a></li>
                          </ul>
                        </div>
                        <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                          <a href="https://admin.alphawealth.io" className="btn btn-link text-decoration-none me-2">Sign In</a>
                          <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                        </div>
                        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop">
                          <div className="offcanvas-header d-flex align-items-center mt-4">
                            <a href="/home" className="d-flex align-items-center mb-md-0 text-decoration-none">
                              <img src="assets/img/logo-color.png" alt="logo" className="img-fluid ps-2" />
                            </a>
                            <button type="button" className="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close"><i className="far fa-close" /></button>
                          </div>
                          <div className="offcanvas-body">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                              <li className="nav-item dropdown">
                                <a className="nav-link " href="0#" role="button" aria-expanded="false">
                                  Home
                                </a>
                              </li>
                              <li><a href="about" className="nav-link">about</a></li>
                              <li><a href="invest" className="nav-link">Invest</a></li>
                              <li><a href="blog" className="nav-link">Blog</a></li>
                              <li><a href="services" className="nav-link">Contact</a></li>
                            </ul>
                            <div className="action-btns mt-4 ps-3">
                              <a href="https://admin.alphawealth.io" className="btn btn-outline-primary me-2">Sign In</a>
                              <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </header>

                  
                  <section className="hero-section ptb-120 text-white bg-dark" style={{background: 'url("assets/img/hero-dot-bg.png")no-repeat center right'}}>
                    <div className="container">
                    <br />
                      <br />
                      <br />
                      <div className="row justify-content-center text-center text-lg-start align-items-center">
                      
                        <div className="col-lg-6 col-md-10">
                          <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                            <h5 className="fw-bold display-6">Smarter investing,<br /> brilliantly personalized.</h5>
                            <p className="lead">Your investment journey starts here. Your ambition, our expertise.</p>
                            <p className="lead" style={{fontSize: '13px'}} Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true} >With PiggyAlpha, it's easier than ever to put your money on a mission.</p>
                            <div className="action-btns mt-5">
                              <a href="https://admin.alphawealth.io" className="btn btn-primary me-lg-3 me-sm-3">Get Started</a>
                              <a href="#footer" className="btn btn-outline-light">Contact Us</a>
                            </div>
                            <div className=" justify-content-center justify-content-lg-start mt-5" >
                              <p><a data-bs-toggle="modal" data-bs-target="#exampleModal" href="0#"><i className="fad fa-play-circle text-primary fa-3x" />  Watch our video</a></p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-8">
                          <div className="hero-img-wrap position-relative app-screen-bg mt-5" style={{backgroundImage: 'url("assets/img/shape/shape-bg-3.svg")'}} >
                            <ul className="position-absolute animate-element parallax-element shape-service d-none d-lg-block">
                              <li className="layer" data-depth="0.03">
                                <img src="assets/img/color-shape/image-4.svg" alt="shape" className="img-fluid position-absolute color-shape-1" />
                              </li>
                              <li className="layer" data-depth="0.02">
                                <img src="assets/img/color-shape/feature-2.svg" alt="shape" className="img-fluid position-absolute color-shape-2 z-5" />
                              </li>
                              <li className="layer" data-depth="0.03">
                                <img src="assets/img/color-shape/feature-3.svg" alt="shape" className="img-fluid position-absolute color-shape-3" />
                              </li>
                            </ul>
                            <img src="assets/img/iphone_x.png" alt="hero" className="img-fluid  z-5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="feature-section ptb-120">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-12">
                          <div className="section-heading text-center" >
                            <h4 className="text-primary h5">Choose better with PiggyAlpha.</h4>
                            <h2>Our Investment Strategies</h2>
                            <p>We have built different strategies which are built to your investment needs.</p>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-lg-3 col-md-6">
                          <div className="position-relative">
                            <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0">
                              <div className="feature-icon d-inline-block bg-success-soft rounded-circle mb-32">
                                <i className="fad fa-seedling text-success fa-2x" />
                              </div>
                              <h3 className="h5">PiggyAlpha Growth Strategy</h3>
                              <p className="mb-0">The Growth Investor: This strategy aims to maximize long-term shareholder wealth by investing in high growth areas.</p>
                              <br/>
                              <br/>
                            </div>
                            <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                              <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="position-relative" >
                            <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                              <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                <i className="fad fa-piggy-bank text-primary fa-2x" />
                              </div>
                              <h3 className="h5">PiggyAlpha Income Strategy</h3>
                              <p className="mb-0">Income-Oriented Investor: For the income-oriented investor. This strategy aims to have an annual yield of 8%, paid out quarterly.</p>
                              <br/><p></p>
                            </div>
                            <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                              <img src="assets/img/shape/dot-big-square.svg" alt="shape" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="position-relative" >
                            <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                              <div className="feature-icon d-inline-block bg-danger-soft rounded-circle mb-32">
                                <i className="fad fa-list-alt text-danger fa-2x" />
                              </div>
                              <h3 className="h5">PiggyAlpha Neutral Strategy</h3>
                              <p className="mb-0">For the conservative investor. This strategy is mainly concerned about capital preservation. It aims to track the performance of the S&P 500 returns without the same level of volatility.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="position-relative" >
                            <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                              <div className="feature-icon d-inline-block bg-warning-soft rounded-circle mb-32">
                                <i className="fad fa-hourglass-start text-danger fa-2x" />
                              </div>
                              <h3 className="h5">PiggyAlpha ESG Strategy</h3>
                              <p className="mb-0">This strategy is for the socially conscious individual. This strategy invests in companies that aim to make the world a better place while also providing shareholder value.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="feature-section pt-60 pb-120">
                    <div className="container">
                      <div className="row align-items-lg-center justify-content-between">
                        <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4" >
                            <h3>TOTAL CONTROL<br /> TOTAL AUTOMATION</h3>
                            <p>No bias. Only sound investment advice. <br />Our portfolios are designed to achieve the optimal risk-return profile backed by empirical research.</p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5" >
                            <li>
                              <span className="d-block mb-4"><i className="fal fa-globe fa-2x text-primary" /></span>
                              <h3 className="h5">Data Driven</h3>
                              <p>We custom-build our portfolios with diversified ETFs to help your money grow with less volatility.</p>
                            </li>
                            <li>
                              <span className="d-block mb-4"><i className="fal fa-money-bill-wave fa-2x text-primary" /></span>
                              <h3 className="h5">Transparent pricing. </h3>
                              <p>Traditional solutions charge a bomb so we keep things simple with a low, annual fee of 1% on your portfolio.</p>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 position-relative" >
                            <ul className="position-absolute animate-element parallax-element shape-service z--1 hide-medium">
                              <li className="layer" data-depth="0.03">
                                <img src="assets/img/color-shape/image-2.svg" alt="shape" className="img-fluid position-absolute color-shape-1" />
                              </li>
                              <li className="layer" data-depth="0.02">
                                <img src="assets/img/color-shape/feature-2.svg" alt="shape" className="img-fluid position-absolute color-shape-2 z-5" />
                              </li>
                              <li className="layer" data-depth="0.03">
                                <img src="assets/img/color-shape/feature-3.svg" alt="shape" className="img-fluid position-absolute color-shape-3" />
                              </li>
                            </ul>
                            <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-5 mx-lg-auto">
                              <div className="mb-5">
                                <h3 className="fw-medium h4">We'll guide you through portfolio options, risk levels, goal setting, and savings features.</h3>
                              </div>
                              <div className="position-relative w-75 mx-auto">
                                <img className="position-relative z-2 w-100 h-auto" src="assets/img/iphone_xs.png" alt="Description" />
                                {/* <img className="position-absolute half-screen" src="assets/img/screen/app-screen-1.jpg" alt="Description" /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="feature-section pt-60 pb-120">
                    <div className="container">
                      <div className="row align-items-lg-center justify-content-between">
                        <div className="col-lg-5 mb-7 mb-lg-0">
                          <div className="mb-4" >
                            <h3>Make investment decisions with data, not with gut feelings</h3>
                            <p>How much do you want to invest? What’s your time horizon? Risk tolerance? PiggyAlpha’s  easy-to-use solution enables you to develop an investing strategy that’s right for you, and our powerful automation helps you implement and maintain it. </p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5" >
                            <li>
                              <span className="d-block mb-4"><i className="fal fa-fingerprint fa-2x text-primary" /></span>
                              <h3 className="h5">Low risk options</h3>
                              <p>Our low volatility funds are suitable for conservative investors looking for diversification, income and moderate returns.</p>
                            </li>
                            <li>
                              <span className="d-block mb-4"><i className="fal fa-shield-check fa-2x text-primary" /></span>
                              <h3 className="h5">Financial planning</h3>
                              <p>We believe financial planning is as important as making money so we have adopted our tools to help our clients manage their finances. </p>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <div className="pr-lg-4 position-relative" >
                            {/*animated shape start*/}
                            <ul className="position-absolute animate-element parallax-element shape-service z--1 hide-medium">
                              <li className="layer" data-depth="0.03">
                                <img src="assets/img/color-shape/image-3.svg" alt="shape" className="img-fluid position-absolute color-shape-1" />
                              </li>
                              <li className="layer" data-depth="0.02">
                                <img src="assets/img/color-shape/feature-1.svg" alt="shape" className="img-fluid position-absolute color-shape-2 z-5" />
                              </li>
                              <li className="layer" data-depth="0.03">
                                <img src="assets/img/color-shape/feature-3.svg" alt="shape" className="img-fluid position-absolute color-shape-3" />
                              </li>
                            </ul>
                            <div className="bg-light text-center shadow-sm rounded-custom overflow-hidden pt-5 px-3 px-lg-5 mx-lg-auto">
                              <div className="mb-5">
                                <h3 className="fw-medium h4">Intelligent, lower fee portfolios designed by experts to help you achieve your financial goals faster.</h3>
                              </div>
                              <div className="position-relative w-75 mx-auto">
                                <img className="position-relative z-2 w-100 h-auto" src="assets/img/Web2.png" alt="Description" />
                                <img className="position-absolute half-screen" src="assets/img/screen/app-screen-2.jpg" alt="Description" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>



                  <section className="work-process ptb-120 bg-light">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8">
                          <div className="section-heading text-center mb-4">
                            <h5 className="h6 text-primary">Instant Setup</h5>
                            <h2>READY, SET, INVEST!</h2>
                            <p>Invest in an intelligent portfolio of low-fee funds that are designed to meet your financial goals.</p>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center">
                        <div className="col-md-6 col-lg-3">
                          <div className="process-card-two text-center px-4 py-5 rounded-custom mt-4">
                            <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                              <i className="fad fa-sign-in-alt fa-2x text-primary" />
                            </div>
                            <h3 className="h5 text-dark">Register</h3>
                            <p className="mb-0">Tell us a little about you. Just answer a few questions, and you are good to go.</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="process-card-two text-center px-4 py-5 rounded-custom mt-4">
                            <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                              <i className="fad fa-bezier-curve fa-2x text-primary" />
                            </div>
                            <h3 className="h5 text-dark ">Set up account</h3>
                            <p className="mb-0">Get started with an investment account and fund your account.</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="process-card-two text-center px-4 py-5 rounded-custom mt-4">
                            <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                              <i className="fad fa-layer-group fa-2x text-primary" />
                            </div>
                            <h3 className="h5 text-dark ">Investment Process</h3>
                            <p className="mb-0">Our expert team would handle your portfolio and the investment processes.</p>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="process-card-two text-center px-4 py-5 rounded-custom mt-4">
                            <div className="process-icon border border-light bg-custom-light rounded-custom p-3">
                              <i className="fad fa-eye fa-2x text-primary" />
                            </div>
                            <h3 className="h5">Monitor</h3>
                            <p className="mb-0"> Monitor your performance and view your holdings. Anytime, anywhere.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="feature-section  ptb-120">
                    <div className="container">
                      <div className="feature-color bg-primary-soft px-5 rounded-custom position-relative">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-lg-5 col-md-12">
                            <div className="feature-content-wrap pe-lg-4 ptb-60 p-lg-0 mb-5 mb-lg-0">
                              <h5 className="text-primary h6 fw-bold">Features</h5>
                              <h2>Portfolio rebalancing - Automation that feels like automagic.</h2>
                              <p>There’s no need to ever make a manual trade. We rebalance your portfolio, automatically diversify deposits, and can help save you taxes, all without you ever lifting a finger.</p>
                              <a href="about" className="btn btn-primary mt-4">Learn More</a>
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-12">
                            <div className="row align-items-center justify-content-center position-relative mt--100 z-2">
                              <div className="col-md-6">
                                <div className="cta-card rounded-custom text-center shadow p-5 bg-white my-4">
                                  <div className="feature-icon d-inline-block bg-dark rounded mb-4">
                                    <i className="fal fa-bezier-curve text-white fa-2x" />
                                  </div>
                                  <h3 className="h5 fw-bold">Dividend reinvestment</h3>
                                  <p className="mb-0">We use dividends to balance your portfolio and help avoid static cash.</p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="cta-card rounded-custom text-center shadow p-5 bg-white my-4">
                                  <div className="feature-icon d-inline-block bg-danger rounded mb-4">
                                    <i className="fal fa-bezier-curve text-white fa-2x" />
                                  </div>
                                  <h3 className="h5 fw-bold">Recurring deposits</h3>
                                  <p className="mb-0">Never miss your milestones by automating your deposits on your preferred schedule.</p>
                                </div>
                                <div className="cta-card rounded-custom text-center shadow p-5 bg-white my-4">
                                  <div className="feature-icon d-inline-block bg-success rounded mb-4">
                                    <i className="fal fa-bezier-curve text-white fa-2x" />
                                  </div>
                                  <h3 className="h5 fw-bold">Our portfolios are designed to help you build wealth.</h3>
                                  <p className="mb-0">Our strategies diversify your investments to optimize returns. You can review any investment strategy’s historical performance before you select it.</p>
                                </div>
                              </div>
                              <ul className="position-absolute animate-element parallax-element z--1">
                                <li className="layer" data-depth="0.06">
                                  <img src="assets/img/shape/shape-bg-3.svg" alt="shape" className="img-fluid" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="faq-section bg-dark ptb-120">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-12">
                          <div className="section-heading text-center" >
                            <h4 className="h5 text-primary">FAQ</h4>
                            <h2>Frequently Asked Questions</h2>
                            <p>Intelligent, lower fee portfolios designed by experts to help you achieve your financial goals faster.</p>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                          <div className="faq-content-wrap d-flex mb-5"  >
                            <span className="faq-icon me-3"><i className="fal fa-info text-primary" /></span>
                            <div className="faq-info">
                              <h5>Can I learn more about PiggyAlpha's investment strategy?</h5>
                              <p className="mb-0">Each of our portfolios is fully diversified across asset classes to help investors achieve a higher rate of return while taking less risk.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                          <div className="faq-content-wrap d-flex mb-5"  >
                            <span className="faq-icon me-3"><i className="fal fa-question text-primary" /></span>
                            <div className="faq-info">
                              <h5>Why should I invest?</h5>
                              <p className="mb-0">Investing allows you to put your money into vehicles that have the potential to earn returns. Investing can help you reach your financial goals such as retirement, buying a home, starting your own business, or funding your children's education.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                          <div className="faq-content-wrap d-flex mb-5"  >
                            <span className="faq-icon me-3"><i className="fal fa-clipboard-list-check text-primary" /></span>
                            <div className="faq-info">
                              <h5>Why don't I just invest on my own?</h5>
                              <p className="mb-0">When investing as an individual, you employ a lot of behavioral bias and you’re prone to regrets. With PiggyAlpha, you will benefit from the constant monitoring, rebalancing, and re-optimisation that we provide, which is nearly impossible if you were to do it on your own.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                          <div className="faq-content-wrap d-flex mb-5"  >
                            <span className="faq-icon me-3"><i className="fal fa-file-invoice-dollar text-primary" /></span>
                            <div className="faq-info">
                              <h5>Does PiggyAlpha charge me a fee for taking money out of my account? </h5>
                              <p className="mb-0">No. We don't charge anything for withdrawals, transferring out, or leaving your account open with a zero balance.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                          <div className="faq-content-wrap d-flex mb-5"  >
                            <span className="faq-icon me-3"><i className="fal fa-user-shield text-primary" /></span>
                            <div className="faq-info">
                              <h5>Are my investments with PiggyAlpha protected?</h5>
                              <p className="mb-0">Your accounts are held with our asset custodian, Fidelity clearing Canada, a subsidiary of Fidelity investments and are protected within specified limits in the event of its insolvency by The Canadian Investor Protection. (TCIP).</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                          <div className="faq-content-wrap d-flex mb-5"  >
                            <span className="faq-icon me-3"><i className="fal fa-credit-card  text-primary" /></span>
                            <div className="faq-info">
                              <h5>What if I want to withdraw my money?</h5>
                              <p className="mb-0">Well, it’s your money, so you can withdraw it at any time.
                                <br />
                                Simple, right? But if you still have questions, we're here to help. Please send us an email or give us a call.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="bg-info shadow-lg p-5 rounded-custom mt-lg-5"  >
                        <div className="row align-items-center justify-content-between">
                          <div className="col-lg-7">
                            <div className="cta-content">
                              <h3 className="mb-2">Have More Questions?</h3>
                              <p className="mb-lg-0 mb-xl-0">Drop us a note and we’ll get back to you soon</p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="text-lg-end">
                              <a href="#footer" className="btn btn-light">Get in Touch</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog ">
                      <div className="modal-content">
                        <div className="modal-body vidio" style={{borderRadius:'10px'}}>
                            <iframe style={{borderRadius:'10px'}} src="https://www.youtube.com/embed/7U-Dz9CXRK0" width={500} height={300} frameBorder={0} webkitallowfullscreen mozallowfullscreen allowFullScreen />
                          </div>
                      </div>
                    </div>
                  </div>
                  <footer className="footer-section" id="footer">
                    <div className="footer-top footer-light ptb-120">
                      <div className="container">
                        <div className="row justify-content-between">
                          <div className="col-md-8 col-lg-5 mb-md-4 mb-lg-0">
                            <div className="footer-single-col">
                              <div className="footer-single-col mb-4">
                                <img src="assets/img/logo-white.png" alt="logo" className="img-fluid logo-white" />
                                <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                              </div>
                              <p>Our latest news, articles, and resources, we will sent to
                                your inbox weekly.</p>
                              <form className="newsletter-form position-relative d-block d-lg-flex d-md-flex">
                                <input type="text" className="input-newsletter form-control me-2" placeholder="Enter your email" name="email" required autoComplete="off" />
                                <input type="submit" defaultValue="Subscribe" data-wait="Please wait..." className="btn btn-primary mt-3 mt-lg-0 mt-md-0" />
                              </form>
                              <div className="ratting-wrap mt-4">
                                <h6 className="mb-0">10/10 Overall rating</h6>
                                <ul className="list-unstyled rating-list list-inline mb-0">
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                            <div className="row">
                              <div className="col-md-4 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                  <h3>Pages</h3>
                                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                                    <li><a href="/home" className="text-decoration-none">Home</a></li>
                                    <li><a href="about" className="text-decoration-none">About</a></li>
                                    <li><a href="invest" className="text-decoration-none">Invest</a></li>
                                    <li><a href="blog" className="text-decoration-none">Blog</a></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-4 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                  <h3>Legal</h3>
                                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                                    <li><a href="terms" className="text-decoration-none">Terms of Service</a></li>
                                    <li><a href="privacy" className="text-decoration-none">Privacy Policy</a></li>
                                  
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer-bottom footer-light py-4">
                      <div className="container">
                        <div className="row justify-content-between align-items-center">
                          <div className="col-md-7 col-lg-7">
                            <div className="copyright-text">
                              <p className="mb-lg-0 mb-md-0">2021 PiggyAlpha. </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4">
                            <div className="footer-single-col text-start text-lg-end text-md-end">
                              <ul className="list-unstyled list-inline footer-social-list mb-0">
                                <li className="list-inline-item"><a href="0#"><i className="fab fa-facebook-f" /></a></li>
                                <li className="list-inline-item"><a href="0#"><i className="fab fa-instagram" /></a></li>
                                <li className="list-inline-item"><a href="0#"><i className="fab fa-twitter" /></a></li>
                                <li className="list-inline-item"><a href="0#"><i className="fab fa-linkedin" /></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
              </div>
      );
    }
  };

export default Home;
