import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';


function Password(){

  const [passwordInput, setPasswordInput] = useState('');

  const history = useHistory();
 
  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  }
  

  const handleLoginSubmit = (e) => {
    console.log(passwordInput);
    let hardcodedCred = {
        password: 'password2341'
    }

    if ((passwordInput === hardcodedCred.password)) {
        const token = 'piggyalphalimited22098274627131822119@quiqw0okqp-pl';
        sessionStorage.setItem('auth-token', token);
        //go to www.website.com/todo
        history.push('/home');
    } else {
        //bad combination
        // alert('wrong email or password combination');
        e.target.reset();
        alert('wrong password, please try again');
    }
  }



      return (
    <div>
       
       
      
        <div className="style-guide">
          <section className="feature-section  ptb-120">
            <div className="container">
              <div className="row align-items-center justify-content-between">
              <div className="col-lg-12 col-md-6">
                <center>
                <div className="feature-content-wrap">
                    <h4 className=" text-dark">Enter password to access</h4>
                    <form className="form" autoComplete="off" onSubmit={(e)=>handleLoginSubmit(e)}>
                     <center>
                     <div className=" mt-14">
                        <div className="col-sm-7">
                          <label htmlFor="email" className="mb-10"></label>
                          <div className="input-group mb-3">
                            <input type="password" 
                            className="form-control" 
                            value={passwordInput}  
                            name="password"
                            onChange={handlePasswordChange}
                             placeholder="Enter Password" 
                             required aria-label="password" />
                          </div>
                          <button type="submit"  className="btn btn-primary">Submit</button>
                        </div>
                      </div>
                     </center>
                     </form>
                  </div>
                  </center>
                </div>
               
              </div>
            </div>
          </section>
        </div>
       
        
                </div>
            
      );
    // }
  };


export default Password;
